@config "../../../tailwind.config.ts";
@tailwind components;

@layer components {
    .label {
        @apply text-[.8125rem] font-[450] leading-[1rem] tracking-[0.26px] text-battleship md:text-[.8125rem] md:leading-4 xl:text-[.8125rem] xl:leading-[1rem];
    }

    /* font-size: 15px*/
    .headline-text-15px {
        @apply text-[.9375rem] font-[550] leading-[1.1875rem] text-inkwell;
    }

    /* font-size: 16px*/
    .headline-text-16px {
        @apply text-base font-[550] leading-[1.25rem] text-inkwell;
    }

    /* font-size: 17px*/
    .headline-text-17px {
        @apply text-[1.0625rem] font-semibold leading-[1.3125rem] text-inkwell;
    }

    /* font-size: 18px*/
    .headline-text-18px {
        @apply text-[1.125rem] font-semibold leading-[1.375rem] text-inkwell;
    }

    /* font-size: 20px*/
    .headline-text-20px {
        @apply text-lg font-semibold leading-[1.625rem] text-inkwell;
    }

    /* font-size: 21px*/
    .headline-text-21px {
        @apply text-[1.3125rem] font-semibold leading-[1.625rem] text-inkwell;
    }

    /* font-size: 24px*/
    .headline-text-24px {
        @apply text-xl font-semibold leading-[1.875rem] text-inkwell;
    }

    /* font-size: 32px*/
    .headline-text-32px {
        @apply text-3xl font-semibold leading-10 text-inkwell;
    }

    /* font-size: 36px*/
    .headline-text-36px {
        @apply text-3xl font-extrabold leading-9 text-inkwell lg:text-4xl lg:leading-10 ;
    }

    /* font-size: 14px*/
    .sub-headline-14px {
        @apply text-sm font-[450] leading-[1.1875rem] text-batcave;
    }

    /* font-size: 15px*/
    .sub-headline-15px {
        @apply text-[0.9375rem] font-[450] leading-5 text-batcave;
    }
}

